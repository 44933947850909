<template>
  <div
    ref="flickityCarousel"
    :class="
      requiresFlickity
        ? 'flickity-carousel-component'
        : 'mx-3 grid gap-y-6 gap-x-0 sm:gap-6 grid-cols-2 sm:grid-cols-2 md:grid-cols-4'
    "
    :style="cssProps">
    <slot></slot>
  </div>
</template>

<script>
import Flickity from "flickity";

export default {
  name: "FlickityCarousel",
  props: {
    requiresFlickity: {
      type: Boolean,
      default: false
    },
    afterHeightOffset: {
      type: String,
      default: "40px"
    },
    buttonOffset: {
      type: String,
      default: "-3rem"
    },
    hideArrows: {
      type: Boolean,
      default: false
    },
    flickityId: {
      type: String,
      default: null
    },
    cellAlign: {
      type: String,
      default: "left"
    },
    pageDots: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update-selected-elements"],
  data() {
    return {
      rightGradientOpacity: 1,
      leftGradientOpacity: 0,
      resizeObserver: null
    };
  },
  computed: {
    cssProps() {
      return {
        "--button-offset": this.buttonOffset,
        "--after-height-offset": this.afterHeightOffset,
        "--left-carousel":
          this.hideArrows || !this.requiresFlickity
            ? 0
            : this.leftGradientOpacity,
        "--right-carousel":
          this.hideArrows || !this.requiresFlickity
            ? 0
            : this.rightGradientOpacity
      };
    }
  },
  mounted() {
    if (this.requiresFlickity) {
      this.$nextTick(() => {
        this.initCarousel();
      });
    }

    window.addEventListener("flickityNavigate", ({ detail }) => {
      const { id, next } = detail;

      if (id === this.flickityId) {
        if (next) {
          this.flkty.next();
        } else {
          this.flkty.previous();
        }
      }
    });
  },
  methods: {
    updateSelectedElements() {
      this.$emit("update-selected-elements", this.flkty.selectedElements);
    },
    resizeFlickity() {
      window.dispatchEvent(new Event("resize"));
    },
    initCarousel() {
      const { flickityCarousel } = this.$refs;
      const options = {
        pageDots: this.pageDots,
        contain: true,
        cellAlign: this.cellAlign,
        groupCells: true,
        imagesLoaded: true,
        prevNextButtons: !this.hideArrows,
        cellSelector: ".carousel-cell",
        arrowShape:
          "M2.896 52.164A4.716 4.716 0 0 1 .6 48a4.716 4.716 0 0 1 2.292-4.164l41.82-42.464a4.572 4.572 0 0 1 6.536 0c1.8 1.832 1.8 4.804 0 6.636L11.86 48l39.388 39.992c1.8 1.832 1.8 4.8 0 6.636a4.572 4.572 0 0 1-6.536 0L2.896 52.16v.004z",

        // These events work around ios 11.3+ bug
        // https://github.com/metafizzy/flickity/issues/740
        on: {
          dragStart() {
            document.ontouchmove = e => {
              e.preventDefault();
            };
          },

          dragEnd() {
            document.ontouchmove = () => {
              return true;
            };
          }
        }
      };
      this.flkty = new Flickity(flickityCarousel, options);
      this.flkty.resize();
      this.updateSelectedElements();
      this.flkty.on("dragStart", () => {
        this.flkty.slider.style.pointerEvents = "none";
      });
      this.flkty.on("dragEnd", () => {
        this.flkty.slider.style.pointerEvents = "auto";
      });
      this.flkty.on("change", index => {
        const maxSlides = this.flkty.slides.length;
        const currentSlide = index + 1;
        this.updateSelectedElements();

        switch (true) {
          case currentSlide === 1:
            this.leftGradientOpacity = 0;
            this.rightGradientOpacity = 1;
            break;
          case currentSlide === maxSlides:
            this.leftGradientOpacity = 1;
            this.rightGradientOpacity = 0;
            break;
          case currentSlide < maxSlides && currentSlide !== 1:
            this.leftGradientOpacity = 1;
            this.rightGradientOpacity = 1;
            break;
          default:
            this.leftGradientOpacity = 0;
            this.rightGradientOpacity = 1;
            break;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.flickity-carousel-component {
  &:after {
    bottom: 0;
    left: 0;
    position: absolute;
    height: calc(100% - var(--after-height-offset));
    width: 100%;
    content: "";
    pointer-events: none;
    background: linear-gradient(
      to left,
      rgba(#faf7f0, var(--right-carousel)) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      rgba(#faf7f0, var(--left-carousel)) 100%
    );
    display: none;

    @media screen and (min-width: $screen-md-min) {
      display: block;
    }
  }

  .flickity-prev-next-button {
    &.next {
      right: var(--button-offset) !important;
    }

    &.previous {
      left: var(--button-offset) !important;
    }
  }
}
</style>
