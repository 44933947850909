<template>
  <div
    class="flex flex-col slider-item carousel-cell w-1/2 md:w-1/3 lg:w-1/4"
    :style="styles">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FlickityCarouselItem",
  props: {
    maxWidth: {
      type: String,
      default: "25%"
    },
    mobileMinWidth: {
      type: String,
      default: "100%"
    },
    lgMinWidth: {
      type: String,
      default: "21.8%"
    },
    smMinWidth: {
      type: String,
      default: "40%"
    },
    mdMinWidth: {
      type: String,
      default: "29%"
    },
    flickityId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      height: null
    };
  },
  computed: {
    cssProps() {
      return {
        "--mobile-min-width": `${this.mobileMinWidth}`,
        "--max-width": `${this.maxWidth}`,
        "--lg-min-width": `${this.lgMinWidth}`,
        "--sm-min-width": `${this.smMinWidth}`,
        "--md-min-width": `${this.mdMinWidth}`
      };
    },
    heightStyle() {
      if (!this.height) {
        return {};
      }

      return {
        height: `${this.height}px`
      };
    },
    styles() {
      return {
        ...this.cssProps,
        ...this.heightStyle
      };
    }
  },
  mounted() {
    window.addEventListener("setCellHeights", e => {
      if (e.detail.id === this.flickityId) {
        this.height = e.detail.height;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.slider-item {
  @media (min-width: $screen-sm-min) {
    min-width: var(--sm-min-width);
  }
  @media (min-width: $screen-md-min) {
    min-width: var(--md-min-width);
  }
  @media (min-width: $screen-lg-min) {
    min-width: var(--lg-min-width);
  }

  min-width: var(--mobile-min-width);
  max-width: var(--max-width);
  width: var(--max-width);
}
</style>
